import { NgTemplateOutlet } from '@angular/common';
import {
  Component,
  DoCheck,
  HostBinding,
  booleanAttribute,
  computed,
  contentChild,
  inject,
  input,
  signal,
  viewChild,
} from '@angular/core';
import { ControlContainer, FormGroupDirective, NgControl, NgForm } from '@angular/forms';

import { FormFieldErrorsComponent, FormFieldErrorsService } from './form-errors';
import { FormFieldLabelDirective } from './label/label.directive';
import { RequiredAsteriskDirective } from './required-asterisk/required-asterisk.directive';

let nextUniqueId = 0;

@Component({
  selector: 'di-form-field',
  standalone: true,
  imports: [RequiredAsteriskDirective, FormFieldErrorsComponent, NgTemplateOutlet],
  providers: [FormFieldErrorsService],
  templateUrl: './form-field.component.html',
  host: {
    class: 'tw-flex tw-flex-col tw-gap-0.5',
  },
})
export class FormFieldComponent implements DoCheck {
  private readonly controlContainer = inject(ControlContainer, { optional: true });
  private readonly formGroupDirective = inject(FormGroupDirective, { optional: true });
  private readonly ngForm = inject(NgForm, { optional: true });

  private readonly inputInvalid = signal(false);
  private readonly inputTouched = signal(false);
  private readonly formSubmitted = signal(false);

  private readonly formErrorsComponent = viewChild(FormFieldErrorsComponent);
  protected readonly labelTemplate = contentChild(FormFieldLabelDirective);
  public readonly ngControl = contentChild(NgControl, { descendants: true });

  public readonly id = `form-field-${++nextUniqueId}` as const;

  public readonly errorMessageId = computed(() => this.formErrorsComponent()?.id);
  public readonly errorsVisible = computed(() => {
    const ngControl = this.ngControl();
    if (ngControl) {
      return this.inputInvalid() && (this.inputTouched() || this.formSubmitted());
    }
    return false;
  });

  public readonly label = input('');
  public readonly required = input(null, { transform: booleanAttribute });

  private get formContainer() {
    if (
      this.controlContainer &&
      this.controlContainer.formDirective instanceof FormGroupDirective
    ) {
      return this.controlContainer.formDirective;
    }
    return this.ngForm;
  }

  ngDoCheck() {
    // not the best solution but we cannot rely on contentChild(NgControl) as of today (2024.08.29)
    // because it does not reflect the newest instance of NgControl (neither contentChildren)
    // which changes when replacing the form, e.g. when switching data entries or canceling the form
    const ngControl = this.ngControl();
    if (ngControl) {
      this.inputInvalid.set(ngControl.invalid);
      this.inputTouched.set(ngControl.touched);
    }
    if (this.formContainer) {
      this.formSubmitted.set(this.formContainer.submitted);
    }
  }

  @HostBinding('class.di-form-field-empty')
  protected get isEmpty() {
    const ngControl = this.ngControl();
    if (!ngControl) {
      return false;
    }
    return (
      ngControl.value === '' ||
      ngControl.value === null ||
      ngControl.value === undefined ||
      (Array.isArray(ngControl.value) && ngControl.value.length === 0)
    );
  }
}
